<template>
  <h2 class="content-block">User Details</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="user" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['userId', 'userName', 'firstName', 'surname']">
      >
      <DxSimpleItem data-field="userId" :editor-options="{readOnly: true}" />
      <DxSimpleItem editor-type="dxTextBox" data-field="userName" :editor-options="{readOnly: !isNewRec()}"/>
      <DxSimpleItem editor-type="dxTextBox" data-field="firstName" :editor-options="{readOnly: !isNewRec()}"/>
      <DxSimpleItem editor-type="dxTextBox" data-field="surname" :editor-options="{readOnly: !isNewRec()}"/>
      <DxSimpleItem editor-type="dxSelectBox" data-field="view" 
        :editor-options="{ items: ['Default','Operator'], searchEnabled: true, readOnly: !isNewRec()}"/>

    </DxForm>
  </div>
  
  <div class="content-block dx-card responsive-paddings" id="pwreset">
    <DxTextBox class="tb" label="Enter New Password" mode="text" :show-clear-button="true" v-model:value="resetPassword1" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>
    <DxTextBox class="tb" label="Renter Password" mode="text" :show-clear-button="true" v-model:value="resetPassword2" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>
  </div>

  <div class="content-block dx-card responsive-paddings" v-if="!isNewRec()">
    <UserGroup ref="groups"></UserGroup>
  </div>

  <DxToast
    v-model:visible="toastVisible"
    v-model:message="toastMessage"
    v-model:type="toastType"
    :position="toastPosition"
  />
  
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import notify from 'devextreme/ui/notify';
import DxTextBox from "devextreme-vue/text-box";
import {
    DxValidator,
    DxStringLengthRule
} from 'devextreme-vue/validator';
import { DxToast } from 'devextreme-vue/toast';
import UserGroup from './user-group.vue';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxTextBox,
    DxValidator,
    DxStringLengthRule,
    DxToast, 
    UserGroup
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      toastPosition: {
          at: "bottom",
          my: "top",
          of: "#pwreset"},
      resetPassword1: "",
      resetPassword2: "",
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      inputAttr: {
        mode: "password",
        inputAttr: {
          autocomplete: "off" //"new-password"
        }
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          if (!this.validatePassword())
          {
            this.toastType = 'error';
            this.toastMessage = 'Password error.  Ensure passwords match and are >= 6 characters in length.';
            this.toastVisible = true;
          }
          else
          {
            if (this.user.userId==0)
            {
              this.$store.dispatch('users/addUser', this.user);
              this.$emit('recordUpdated');
            }
            else
            {
              this.$refs.groups.saveGroupMembership();
              // this.$store.dispatch('users/updateUser', this.user);
              // this.$emit('recordUpdated');
            }
          }
        },
      },
    };
  },
  computed: {
    
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    user() {
      return this.$store.getters["users/getUser"];
    },
  },
  methods: {
    isNewRec(){
      return (this.currentRecordId==0)
    },
    refreshData(){
      this.loadPageData();
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    validatePassword(){
      if (this.isNewRec() && this.resetPassword1 === this.resetPassword2 && this.resetPassword1.length>=6 )
      {
        this.user.pwHash = this.resetPassword1;
        return true;
      }
      if (!this.isNewRec() && this.resetPassword1 === this.resetPassword2 && this.resetPassword1.length>=6 )
      {
        this.user.pwHash = this.resetPassword1;
        return true;
      }
      if (!this.isNewRec() && this.resetPassword1 === this.resetPassword2 && this.resetPassword1.length==0 )
      {
        //don't update the password, it's just an update to other properties
        return true;
      }
      return false;
    },
    async loadPageData(refresh = false) {
      
      try {
        await this.$store.dispatch("users/loadUser", { id: this.currentRecordId });
        // var d = new Date();
        // var datestring =  d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        // console.log(datestring);

      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.tb{
  margin: 10px 0 30px 0px;
}
</style>
