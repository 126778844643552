<template>
  <div class="aligncenter">
    <img :src="getPath()" />
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      logo_path: "",
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    getPath(){
      return this.logo_path;
    },
    async loadData(refresh = false) {
      try {
        //await this.$store.dispatch("areas/loadArea", { id: this.currentRecordId });
        await this.$store.dispatch("customers/loadCustomer");
        this.logo_path = "img/" + this.$store.getters["customers/getCustomer"].logo;
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style lang="scss">
</style>
