<template>
  <div class="ph" id ="header">
    <h2 class="content-block">Logs ❯ Injection</h2> <!-- unicode U+276F : ❯ -->

    <DxLoadPanel
      :position="{ of: '#dataDiv' }"
      v-model:visible="loadingVisible"
      :message="Loading"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
      :on-shown="onShown"
      :on-hidden="onHidden"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>

  <div class="divGroup" id ="dataDiv">
    <div class="mininote">*Injection = (StartVol - EndVol + Fills) / Min(14,{DaysOfData})</div>
    <DxDataGrid
      :data-source="log"
      :key-expr="logId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :allow-column-reordering="true"
      no-data-text="No data"
      @initialized="saveGridInstance"
      :style="{ 'max-height': datagridHeight }"
    >
    
    <DxExport :enabled="true" />
    <DxColumnChooser :enabled="true" mode="select" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxLoadPanel :enabled="true" />
    <DxScrolling mode="standard" />     <!-- mode="virtual" -->
    <DxColumnFixing :enabled="true" />
    <DxPager
      :show-info="true"
      :show-page-size-selector="true"
      :allowed-page-sizes="[20, 50, 100]"
      :show-navigation-buttons="true"
    />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxStateStoring,
  DxColumnFixing,
  DxPager,
  DxItem,
  DxLookup,
  DxToolbar,
  DxExport
} from "devextreme-vue/data-grid";
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxDataGrid,
    // DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    // DxStateStoring,
    DxColumnFixing,
    DxPager,
    // DxItem,
    // DxLookup,
    // DxToolbar,
    DxExport,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      currentMode: 'widget',
      dataGridInstance: null,
      loadingVisible: false,
      datagridHeight: '40vh',
      log:[],
    };
  },
  methods: {
    setDimensions() {
      const h = document.documentElement.clientHeight;
      // const dgh = (h<800) ? (h-25) : (h*.5)
      const dgh = (h-250);
      // this.datagridHeight= (h*.5) + 'px';
      this.datagridHeight= dgh + 'px';
    },
    changeResizingMode(data) {
      this.currentMode = data.value;
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    async loadPageData(refresh = false) {
      try {
        this.loadingVisible = true;
        await this.$store.dispatch("logInjections/loadLogInjections", {forceRefresh: refresh,});
        this.log = this.$store.getters["logInjections/getLogInjections"]
        this.loadingVisible = false;
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
    },
  },
};

</script>

<style scoped>
  .liquidFillGaugeText { 
    font-family: Helvetica; 
    font-weight: bold; 
  }
  svg {
    margin-top: 3em; 
  }
  .ph { /* page header */
    /* height: 50px; */
    /* margin-bottom: 50px; */
    margin: 40px 0px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  .divGroup {
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    /* border-color: rgb(255, 100, 0); */
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.2); */
    /* text-align: center; */
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .pheader {
    font-size: 14px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .pval {
    font-size: 22px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .floater{
    position: absolute;
    /* top: 10px; */
    right: 0;
    z-index: 10;
  }
  
</style>
