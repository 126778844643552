<template>
  <div>
    <DxDataGrid class="dx-card wide-card" :data-source="containers" :key-expr="containerId" :hover-state-enabled="true"
      :selection="{ mode: 'single' }" :show-borders="true" :columns-auto-width="false" :column-min-width="50"
      :allow-column-resizing="true" :column-resizing-mode="dataGridColResizeMode" :allow-column-reordering="true"
      no-data-text="Loading ..." @row-click="datagridRowClick" 
      @initialized="setGridInstance" @content-ready="dataReady">
      <!-- @selection-changed="datagridSelectionChanged" -->
      <!-- :style="{ 'max-height': datagridHeight }" -->

      <DxStateStoring :enabled="true" type="localStorage" storage-key="rep_status_container_grid" />

      <DxToolbar>
        <DxItem location="before" template="filterStatusCriticalTemplate" />
        <DxItem location="before" template="filterStatusMarginalTemplate" />
        <DxItem location="before" template="filterStatusGoodTemplate" />
        <DxItem location="before" template="filterStatusAllTemplate" />
        <DxItem location="after">
          <div class="mininote">Rows: {{ datagridRowCount }} </div>
        </DxItem>
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>

      <template #filterStatusCriticalTemplate>
        <DxButton text="❌ " hint="Filter Critical Values" @click="filterToggle('❌')" />
      </template>
      <template #filterStatusMarginalTemplate>
        <DxButton text="⚠️ " hint="Filter Marginal Values" @click="filterToggle('⚠️')" />
      </template>
      <template #filterStatusGoodTemplate>
        <DxButton text="✔️ " hint="Filter Good Values" @click="filterToggle('✔️')" />
      </template>
      <template #filterStatusAllTemplate>
        <DxButton text="All" hint="All Statuses" @click="filterToggle('')" />
      </template>
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" hint="Refresh Data" @click="refreshData" />
      </template>
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>

      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" /> <!-- mode="virtual" -->
      <DxColumnFixing :enabled="true" />
      <DxPager :show-info="true" :show-page-size-selector="true" :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true" />

      <DxColumn id="stat" data-field="containerStatus" fixed="true" caption="" width="55px" alignment="left"
        :allow-resizing="false" :allow-filtering="true" :show-in-column-chooser="false" :allow-reordering="false" />
      <DxColumn data-field="operator" caption="Operator" min-width="125" />
      <DxColumn data-field="area" caption="Area" min-width="125" />
      <DxColumn data-field="serialnum" caption="Device" :visible="false" min-width="125" />
      <DxColumn data-field="containerName" caption="Name" :allow-reordering="true" min-width="150" />

      <!-- <DxColumn v-for="prop in properties Object.keys(containers[0])" :key="prop" :data-field="prop" :caption="prop" -->
      <DxColumn v-for="prop in properties" :key="prop" :data-field="prop" :caption="prop" alignment='left' min-width="100" :visible="true" /> 
    </DxDataGrid>
  </div> 
  <div>
    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="800"
      :height="600"
      :title=this.getContainerVal(this.currentContainer.containerName)
      content-template="tank-info"
    >
      <template #tank-info>
        <DxBox :height="550" direction="row" width="100%" >
          <DxItem :ratio="5">
            <DxScrollView :width="750" :height="450" direction="both">  <!-- or 'horizontal' | 'vertical' -->
              <div v-for="prop in properties" :key="prop">
                <div class="pheader">{{ prop }}</div>
                <div class="pval">{{this.getContainerVal(this.currentContainer[prop])}}</div>
              </div>
            </DxScrollView>
          </DxItem>
          <DxItem :ratio="1">
            <div></div>
          </DxItem>
          <DxItem :ratio="4">
            <div class="divGroup" id ="history"> 
              <!-- graphs-->
              <div v-if="!showHistory">
                <DxButton text="Load Volume History" type="default" hint="Volume History" @click="loadHistory()" width="100%" styling-mode="outlined" />
              </div>
              <div v-else >
                <DxChart :data-source="containerVolumeHistory">
                  <DxZoomAndPan argument-axis="none" value-axis="none"/>
                  <DxCommonSeriesSettings
                    argument-field="historyDate"
                    value-field="propValue"
                    type="line"
                    show-in-legend="false"
                    color="#9090FF"
                  />
                  <DxLegend
                    vertical-alignment="bottom"
                    horizontal-alignment="center"
                    item-text-position="bottom"
                    visible="false"
                  />
                  <DxSeriesTemplate name-field="containerName" visible="false" />
                  <DxTitle text="Tank History">
                    <DxSubtitle text="(Volume %)" />
                  </DxTitle>
                  <!-- DXPopUp has a very high zIndex, set this higher or it won't show -->
                  <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="20000"/>
                  <DxValueAxis :visual-range="[0, 80]">  
                    <DxStrip :start-value="0" :end-value="15" color="#FF000010" />
                  </DxValueAxis>
                </DxChart>
              </div>
            </div>
          </DxItem>
        </DxBox>
      </template>
    </DxPopup>  
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar,
  DxItem,
  DxStateStoring,
  DxMasterDetail,
  DxPaging,
  DxPager,
  DxColumnFixing
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxTextBox from "devextreme-vue/text-box";
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxSeries,
  DxZoomAndPan,
  DxStrip
} from "devextreme-vue/chart";
import { DxBox } from 'devextreme-vue/box';

export default {
  components: {
    DxButton,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxPager,
    DxScrolling,
    DxStateStoring,
    DxToolbar,
    DxPopup,
    DxScrollView,
    DxChart,
    DxZoomAndPan,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxValueAxis,
    DxStrip,
    DxBox,
    
  },
  created() {
    this.loadData();
  },
  props: ['typeId'],
  data() {
    return {
      dataGridColResizeMode: 'widget',
      dataGridInstance: null,
      datagridHeight: '40vh',
      selectedContainer: null,
      loadingVisible: false,
      // isLoadPanelVisible: false,
      datagridRowCount: 0,
      properties:[],
      containers:[],

      currentContainer: "",
      popupVisible: false,
      showHistory: false,
      position : { of: '#history' },
      showIndicator : true,
      shading : true,
      showPane : true,
      hideOnOutsideClick : false,
    };
  },
  computed: {
    containerVolumeHistory(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerPropertyHistory"];
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (%)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
  },
  methods: {
    dataReady() {
      this.datagridRowCount = this.dataGridInstance.totalCount();
    },
    setGridInstance(e) {
      this.dataGridInstance = e.component;
      this.dataGridInstance.columnOption(0, 'allowHeaderFiltering', false);
    },
    removeFilter() {
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    refreshData() {
      this.loadData();
    },
    async filterToggle(filterArg) {
      this.dataGridInstance.columnOption(0, 'filterValue', filterArg);
    },
    datagridRowClick(e) {
      this.currentContainer = e.component.getSelectedRowKeys()[0];
      this.$emit('select-container', this.currentContainer.containerId, this.currentContainer);
      // this.currentContainer = e.component.getSelectedRowKeys()[0];
      this.showHistory = false;
      // this.popupVisible = true;
    },
    gotoDetail() {
      this.$router.push({ name: 'reporting-containers-status-details', params: { id: this.selectedContainer } });
    },
    gotoHistory() {
      this.$router.push({ name: 'reporting-containers-history-id', params: { id: this.selectedContainer } });
    },
    setProperties() {
      let retVal = [];
      let flds = this.$store.getters["properties/getProperties"];
      for (let i = 0; i < flds.length; i++) {
        if (flds[i].uiShowList && flds[i].appliesTo == "C" && flds[i].containerTypeId == this.typeId){
          retVal.push(flds[i].propertyName);
        }
      }   
      this.properties = retVal;   
    },
    showDetails(e){
      this.currentContainer = e.component.getSelectedRowKeys()[0];
      // console.log("CURRENT")
      // console.log(this.currentContainer)
      this.showHistory = false;
      this.popupVisible = true;
    },
    loadHistory(){
      this.loadingVisible = true;
      if (this.currentContainer.containerId != null && this.currentContainer.containerId !== undefined && this.currentContainer.containerId > 0)
      {
        this.$store.dispatch("containers/resetContainerPropertyHistory");
        this.$store.dispatch("containers/loadContainerPropertyHistory", {container: this.currentContainer.containerId, propertyName: 'Volume (%)'});
        console.log("HISTORY",this.containerVolumeHistory)
        this.showHistory = true;
      }
      this.loadingVisible = false;
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} </span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    getContainerVal(propVal){
      if (propVal !== undefined && propVal != null )
      {
        return propVal;
      }
      else
      {
        return '-';
      }
    },
    async loadData(refresh = false) {
      try {
        await this.$store.dispatch("properties/loadProperties");
        await this.$store.dispatch("containers/loadContainersStatus", { forceRefresh: refresh });
        this.setProperties();
        console.log(this.properties);
        this.containers = this.$store.getters["containers/getContainersFlatProperties"].filter((data) => data.containerTypeId==this.typeId);
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
  .mininote {
    text-align: left;
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  .pheader {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 16px;
    z-index: 20;
  }
  .pval {
    font-size: 16px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
</style>
