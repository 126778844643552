export  default {
    devices(state) {
        return state.devices;
    },
    hasDevices(state) {
        return state.devices && state.devices.length > 0;
    },
    getActiveDevices(state) {
        return state.activeDevices;
    },
};