import VueCookies from 'vue-cookies'; 

export default {

  async login(context, payload){

    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/Authenticate/login`;
    // var url = "https://r3backendprd.azurewebsites.net/api/Authenticate/login";

    // console.log("AUTH/LOGIN");
    // console.log(url);
    // console.log(payload.username);
    // console.log(payload.pw);

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        userName: payload.username,
        pw: payload.pw
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseData = await response.json();

    context.commit('setUser', {
      username: payload.username,
      token: responseData.token,
      expiration: responseData.expiration
    })
    //.config(expires[,path[, domain[, secure[, sameSite]]])
    VueCookies.config('7d', '/', '', '', 'Lax')
    // VueCookies.set('token', responseData.token);
    VueCookies.set('token', responseData.token);
    // console.log("TOKEN",VueCookies.get('token'))
  },
  logout(context, payload) {
    context.commit('setUser', {
      username: null,
      token: null,
      expiration: null
    })
    VueCookies.remove('token');
  },
};
