<template>
  <h2 class="content-block">User Details</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="user" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['userId', 'userName']">
      >
      <DxSimpleItem data-field="userId" :editor-options="{readOnly: true}" />
      <DxSimpleItem editor-type="dxTextBox" data-field="userName" :editor-options="{readOnly: true}"/>
    </DxForm>
  </div>
  
  <div class="content-block dx-card responsive-paddings" id="pwreset">
    <DxDataGrid 
        class="dx-card wide-card" 
        :data-source="groups" 
        :show-borders="true"
        :columns-auto-width="true" 
        :column-min-width="50" 
        :hover-state-enabled="true" 
        :key-expr="groupId"
        :selected-row-keys="selectedItemKeys"
        @selection-changed="selectionChanged"
        :column-resizing-mode="nextColumn" 
        :allow-column-resizing="true" 
        style="max-height: 80vh"
        @initialized="saveGridInstance"
      >
        <DxSelection mode="multiple" :allow-select-all="false"/>
        <DxSorting mode="none"/>
        <DxPaging :enabled="false" />
      </DxDataGrid>
    </div>

  <DxToast
    v-model:visible="toastVisible"
    v-model:message="toastMessage"
    v-model:type="toastType"
    :position="toastPosition"
  />
  
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import notify from 'devextreme/ui/notify';
import { DxToast } from 'devextreme-vue/toast';
import {
  DxDataGrid,
  DxSelection,
  DxSorting,
  DxPaging
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxToast, 
    DxDataGrid,
    DxSelection,
    DxSorting,
    DxPaging
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          // if (this.user.userId==0)
          // {
          //   this.$store.dispatch('users/addUser', this.user);
          //   this.$emit('recordUpdated');
          // }
          // else
          // {
          //   this.$store.dispatch('users/updateUser', this.user);
          //   this.$emit('recordUpdated');
          // }
        },
      },
      selectedItemKeys: [],
      selectionChanged: (data) => {
        this.selectedItemKeys = data.selectedRowKeys;
      },
    };
  },
  computed: {
    isNewRec(){
      return (this.currentRecordId==0)
    },
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    user() {
      return this.$store.getters["users/getUser"];
    },
    groups() {
      return this.$store.getters["groups/getGroups"];
    },
    userGroups() {
      return this.$store.getters["users/getUserGroups"];
    },
  },
  methods: {
    setGridMembership(){
      let membership = this.userGroups.map(a=>a.groupId);
      let i = 0;
      while (i<this.groups.length)
      {
        if (membership.includes(this.groups[i].groupId))
        {
          this.dataGridInstance.selectRowsByIndexes(i, true);
        }
        i++;
      }
    },
    refreshData(){
      this.loadPageData();
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("users/loadUser", { id: this.currentRecordId });
        await this.$store.dispatch("groups/loadGroups");
        await this.$store.dispatch("users/loadUserGroups", { id: this.currentRecordId });
        this.setGridMembership();
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.tb{
  margin: 10px 0 30px 0px;
}
</style>
