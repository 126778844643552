export default {
 
  async loadPermissionOperators(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissionoperators`;
        
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const permarea = {
        permOperatorId: responseJson[key].permOperatorId,
        groupId: responseJson[key].groupId,
        operatorId: responseJson[key].operatorId,
        };

      responseData.push(permarea);
        
    }
    context.commit('setPermOperators', responseData)
  },

};
