import common from '../common.js';

export default {
  async loadPropertyLists(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'propertylists')
    const responseData = [];

    for (const key in responseJson) 
    {
      const pl = {
        propListId: responseJson[key].propListId,
        propertyId: responseJson[key].propertyId,
        listValue: responseJson[key].listValue,
        propertyExternal: responseJson[key].propertyExternal,
        };
      responseData.push(pl);        
    }
    context.commit('setPropertyLists', responseData)
    
  },
  async loadPropertyList(context, payload ) 
  {
    const id = payload.id;

    let pl = {
      propListId: 0,
      propertyId: 0,
      listValue: "",
      propertyExternal: ""
    };

    if (id != 0)
    {
      const responseJson = await common.getRequest(context, payload, `propertylists/${id}`)
      pl = {
        propListId: responseJson.propListId,
        propertyId: responseJson.propertyId,
        listValue: responseJson.listValue,
        propertyExternal: responseJson.propertyExternal,
        };
    }
    context.commit('setCurrentPropertyList', pl)    
  },

  async loadProperties(context, payload ) {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/properties`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const prop = {
        propertyId: responseJson[key].propertyId,
        propertyName: responseJson[key].propertyName,
        validationRegex: responseJson[key].validationRegex,
        propertySort: responseJson[key].propertySort,
        propertyType: responseJson[key].propertyType,
        propertyClassId: responseJson[key].propertyClassId,
        useThresholds: responseJson[key].useThresholds,
        appliesTo: responseJson[key].appliesTo,
        uiShowList: responseJson[key].uiShowList,
        containerTypeId: responseJson[key].containerTypeId,
        };
      responseData.push(prop);
    }
    context.commit('setProperties', responseData)
  },

  async addPropertyList(context, payload) {
    const newRequest = {
      propListId: payload.propListId,
      propertyId: payload.propertyId,
      listValue: payload.listValue,
      propertyExternal: payload.propertyExternal,
    };
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/propertylists`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }
    
    const responseJson = await response.json();

    payload.propListId = responseJson.propListId;
    context.commit('addPropertyList', payload);
  },

  async updatePropertyList(context, payload) {
    const newRequest = {
      propListId: payload.propListId,
      propertyId: payload.propertyId,
      listValue: payload.listValue,
      propertyExternal: payload.propertyExternal,
    };
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/propertylists/${payload.propListId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });
    
    console.log(newRequest);
    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }
    // const responseJson = await response.json();
    // console.log(responseJson);
    // payload.propListId = responseJson.propListId;
    context.commit('updatePropertyList', payload);
  },
};
