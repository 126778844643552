<template>

  <div v-if="(getUserView()=='operator')">
    <div v-if="$isMobile()">
      <DataOperatorMobile />
    </div>
    <div v-else>
      <DataOperatorDesktop />
    </div>
  </div>
  
  <!-- (getUserView()=='customer') || "" -->
  <div v-else>
    <div v-if="$isMobile()">
      <DataMobile />
    </div>
    <div v-else>
      <DataDesktop />
    </div>
  </div>
  
</template>

<script>
import DataDesktop from "./container-list-desktop.vue";
import DataOperatorDesktop from "./container-list-v-operator-d.vue";
import DataMobile from "./container-list-mobile.vue";
import DataOperatorMobile from "./container-list-v-operator-m.vue";

export default {
  created() {
    this.getUserView();
  },
  components: {
    // eslint-disable-next-line
    DataDesktop, 
    // eslint-disable-next-line
    DataMobile,
    DataOperatorDesktop,
    DataOperatorMobile
  },
  methods:{
    getUserView()
    {
      var token = this.$store.getters["auth/getToken"];
        
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
  
      const tokenDetails = JSON.parse(jsonPayload);
      
      // console.log("TOKEN", tokenDetails);
      return tokenDetails.View.toLowerCase();
    }
  }
};
</script>

<style scoped>
  .ph { /* page header */
    height: 50px;
  } 
  
</style>
