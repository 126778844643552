export default {
  async loadCustomer(context, payload ) {
    var token = context.rootGetters['auth/getToken'];
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/customers`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error("Failed to fetch.");
      throw error;
    }

    const responseJson = await response.json();

    const customer = {
      customerId: responseJson.customerId,
      customerName: responseJson.customerName,
      logo: responseJson.logo,
      };
    
    context.commit('setCustomer', customer)
  },
};
