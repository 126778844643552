export default {
  async updateOffice(context, payload) {

    const newRequest = {
      officeId: payload.officeId,
      districtId: payload.districtId,
      officeName: payload.officeName,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/offices/${payload.officeId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateOffice', payload);
  },

  async addOffice(context, payload) {
    const newRequest = {
      officeId: payload.officeId,
      districtId: payload.districtId,
      officeName: payload.officeName,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/offices`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.officeId = responseJson.officeId;
    context.commit('addOffice', payload);
  },

  async loadOffices(context, payload ) 
  {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/offices`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      const office = {
        officeId: responseJson[key].officeId,
        districtId: responseJson[key].districtId,
        officeName: responseJson[key].officeName,
        };
      responseData.push(office);
    }
    context.commit('setOffices', responseData)
  },

  async loadOffice(context, payload ) {
    const id = payload.id;

    let office = {
      officeId: 0,
      districtId: 0,
      officeName: "",
    };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/offices/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      office = {
        officeId: responseJson.officeId,
        districtId: responseJson.districtId,
        officeName: responseJson.officeName,
        };
    }
    context.commit('setCurrentOffice', office)
  },
};
