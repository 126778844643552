export  default {
    getOperators(state) {
        return state.operators;
    },
    getCurrentOperator(state) {
        return state.currentOperator;
    },
    getOperatorsAreas(state) {
        return state.operatorsAreas;
    },

};