<template>
  <DxDrawer
    opened-state-mode="shrink"
    position="right"
    reveal-mode="expand"
    v-model:opened="drawerOpen"
    :close-on-outside-click="false"
    template="list">

    <template #list>
        <!-- <div class="divGroup" style="width: 40vw"> -->
        <div class="divGroup">
        <div style="width: 45vw">
          
          <div class="propHeader">{{ selectedProp.propertyName }}</div>
          <div class="propVal">{{ selectedProp.status}} {{selectedProp.propValue}}</div>
          <!-- <div class="propHeader">Id</div> -->
          <!-- <div class="propVal">{{ selectedProp.propertyId }}</div> -->
          <!-- <div class="propHeader">Container Prop Id</div> -->
          <!-- <div class="propVal">{{ selectedProp.containerPropId }}</div> -->
                    
          <div class="propThreshold"><DxTextBox :width="auto" label="Marginal Threshold" :value="selectedProp.valueMarginal" /></div>
          <div class="propThreshold"><DxTextBox :width="auto" label="Critical Threshold" :value="selectedProp.valueCritical" /></div>
          <div class="buttons">
            <DxButton class="saveBtn" icon="save" text="Save" @click="closeProperty"/>
            <DxButton class="cancelBtn" text="Cancel" @click="closeProperty"/>
          </div>
          
        </div>
        </div>
    </template>
    <div class="divFilter">
      <DxBox :height="45">
        <DxItem :ratio="1">
          <DxTextBox
            class = "filterSearch"
            placeholder="Search properties..."
            :width="auto"
            :show-clear-button="true"
            v-bind:value="searchVal"
            @value-changed = "searchChange"
          />
        </DxItem>
        <DxItem :ratio="1">
          <DxButtonGroup
            class="filterBtn"
            :items="statusFilter"
            :selected-item-keys="['all']"
            key-expr="status"
            styling-mode="outline" 
            @selection-changed="filterToggle"
          />
        </DxItem>
        
      </DxBox>
    </div>
    <hr style="height:1px;border:1px solid #444;"/>
    <DxScrollView :width="auto" :height="400" direction="both">  <!-- 'horizontal' | 'vertical' -->
      <div class="divGroup" id ="containerInfo">
        <div v-for="prop in container.properties" :key="prop.containerPropId">
          <div v-if="filterCheck(prop.propertyName, prop.status)">
            <!-- <div class="propHeader" v-on:mouseenter="setEditVisibility(prop.propertyName)" v-on:mouseout="setEditVisibility('')">  -->
            <div class="propHeader" v-on:mouseover="setEditVisibility(prop.propertyName)"> 
                <DxButton v-if="(this.ctrlOver==prop.propertyName) && (prop.propertyType==='NUMBER') && (prop.useThresholds)" style="float: right; border-radius:50%" icon="edit" @click="openProperty(prop)"/>
              <div class="propHeader">{{prop.propertyName}}</div>
              <div class="propVal">{{prop.status}} {{prop.propValue}}</div>
              <div v-if="prop.propertyType==='NUMBER' && prop.useThresholds">
                <div class="propThreshold">Critical: {{prop.valueCritical}}</div>
                <div class="propThreshold">Marginal: {{prop.valueMarginal}}</div>
              </div> 
            </div>
            <hr style="height:1px;border:1px solid #444;"/>
          </div>
        </div>
      </div>  
    </DxScrollView>
  </DxDrawer>
</template>

<script>

import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxButtonGroup from 'devextreme-vue/button-group';
import DxDrawer from 'devextreme-vue/drawer';

export default {
  components: {
    DxScrollView,
    DxTextBox,
    DxButton,
    DxBox,
    DxItem,
    DxButtonGroup,
    DxDrawer,
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadPageData()
  },
  data() {
    return {
      // container: {},
      searchVal: "",
      searchStatus: "",
      ctrlOver: "",
      selectedProp: "",
      drawerOpen: false,
      selectedOpenMode:'push',
      selectedPosition :'right',
      selectedRevealMode :'expand',
      statusFilter : [
        {
          status: '',
          hint: 'All',
          text: '✪'
        },
        {
          status: '✔️',
          hint: 'Good',
          text: '✔️'
        },
        {
          status: '⚠️',
          hint: 'Marginal',
          text: '⚠️'
        },
        {
          status: '❌',
          hint: 'Critical',
          text: '❌'
        },
      ],
    };
  },
  // watch: {
  //   async containerId (){
  //     await this.$store.dispatch("containers/loadContainerStatus", this.containerId )
  //     this.container = this.$store.getters["containers/getContainerStatus"];
  //   },
  // },
  computed: {

  },
  methods: {
    loadPageData() {
    },
    searchChange(e){
      this.searchVal = e.value;     
    },
    setEditVisibility(ctrlName){
      this.ctrlOver = ctrlName;
    },
    filterToggle(e){
      if(e.addedItems[0]) {
        this.searchStatus = e.addedItems[0].status;
      }
    },
    filterCheck(propName, propStatus) {
      let filter = false;
      
      filter = propName.toLowerCase().includes(this.searchVal.toLowerCase());
      if (filter && this.searchStatus.length>0)
      {
        filter = (propStatus == this.searchStatus);
      }
      // console.log(propName, filter);
      return filter;
    },
    openProperty(prop){
      this.selectedProp = prop;
      this.drawerOpen = !this.drawerOpen;
    },
    closeProperty(){
      this.drawerOpen = !this.drawerOpen;
    }
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .divFilter {
    width: 85%;
    border-style: none;
    margin: 0 20px 0 20px;
    padding: 20px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
  .filterSearch {
    margin: 0 10px 0 0;
    border-radius: 6px;
  }
  .filterBtn {
    /* margin: 0 0 0 0; */
    padding: 5px;
    width: 30%;
    border-radius: 6px;
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.7);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 14px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    width: 80%;
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
    padding: 2px;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .buttons {
    width: 200px;
    margin: 0 auto;
    display: inline;
  }
  .saveBtn {  
    color: black;  
    background-color: rgb(255, 115, 0);  
    /* margin: 0 20px 20px 0; */
    margin: 0  10px auto;
    width: 100px;
    display: inline-block;
    /* float: left; */
  } 
  .cancelBtn {  
    color: black;  
    background-color: rgb(255, 255, 255,0.3);  
    /* margin: 0 20px 20px 0; */
    margin: 0 auto;
    width: 100px;
    display: inline-block;
    /* float: right; */
    /* display: flex; */
    /* justify-content: flex-end; */
  } 
  .buttons {
    width: 100px;
    margin: 0 auto;
  }

</style>
