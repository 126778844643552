<template>
  <h2 class="content-block">Device Type Details</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="deviceTypes" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['deviceTypeId', 'deviceTypeName']">
      >
      <DxSimpleItem data-field="deviceTypeId"  :editor-options="{readOnly: true}" />
      <DxSimpleItem data-field="deviceTypeName" />
    </DxForm>
  </div>
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import notify from 'devextreme/ui/notify';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          //this.refreshData();
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          if (this.deviceTypes.deviceTypeId==0)
          {
            this.$store.dispatch('deviceTypes/addDeviceType', this.deviceTypes);
            this.$emit('recordUpdated');
          }
          else
          {
            this.$store.dispatch('deviceTypes/updateDeviceType', this.deviceTypes);
            this.$emit('recordUpdated');
          }
        },
      },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    deviceTypes() {
      return this.$store.getters["deviceTypes/getCurrentDeviceType"];
    },
  },
  methods: {
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("deviceTypes/loadDeviceType", { id: this.currentRecordId });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
</style>
