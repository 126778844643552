export  default {
    setAreas(state, payload) {
        state.areas = payload;
    },
    updateArea(state, payload) {
        const area = state.areas.find(x=>x.areaId===payload.areaId);
        area.regionId = payload.regionId;
        area.areaName = payload.areaName;
    },
    addArea(state, payload) {
        state.areas.push(payload);
    },
    setCurrentArea(state, payload) {
        state.currentArea = payload;
    },
    setResponseCode(state, payload) {
        console.log("RESPONSECODE",payload)
        state.responseCode = payload;
    }
};