<template>
  <!-- <div class="ph" id ="title"> -->
  <div class="ph">
    <!-- <h2 class="content-block">Reporting ❯ Tank Status</h2> -->
    <h2 class="content-block">{{title}}</h2> 
  </div>  
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      // title: ""
    };
  },
};
</script>

<style scoped>
  .ph { /* page header */
    /* height: 50px; */
    /* margin-bottom: 50px; */
    margin: 40px 0px;
  } 
  .pheader {
    font-size: 14px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
</style>
