export default {
    getContainer(state) {
        return state.container;
    },
    getContainers(state) {
        return state.containers;
    },
    gridContainers(state) {
        return state.gridContainers;
    },
    gridGridContainers(state) {
        return state.gridContainers;
    },
    hasGridContainers(state) {
        return state.gridContainers && state.gridContainers.length > 0;
    },
    selectedContainer(state) {
        return state.selectedContainer;
    },
    getContainerHistory(state) {
        return state.containerHistory;
    },
    getContainerHistoryDetail(state) {
        return state.containerHistoryDetail;
    },
    getContainerPropertyHistory(state) {
        return state.containerPropertyHistory;
    },
    getContainersStatus(state) {
        // const filData =state.containersStatus.filter(item => state.containersStatusFilter.includes(item.containerStatus));
        // console.log("GET LOADED DATA",filData);
        if (state.containersStatus == null) {
            return null;
        }
        if (state.containersStatusFilter == null) {
            return state.containersStatus;
        }
        else {
            return state.containersStatus.filter(item => state.containersStatusFilter.includes(item.containerStatus))
        }
        // return state.containersStatus;
        // return state.containersStatus.filter(item => state.containersStatusFilter.includes(item.containerStatus));
    },
    getContainersFlatProperties(state){
        const ctrs = state.containersStatus;
        for (const key in ctrs) {
            for (const propKey in ctrs[key].properties) {
                let pname = ctrs[key].properties[propKey].propertyName; 
                ctrs[key][pname] = ctrs[key].properties[propKey].propValue;
            }
        }
        return ctrs;
    },
    getContainersFlatProperties_ViewOperator(state){
        const ctrs = state.containersStatus;
        for (const key in ctrs) {
            for (const propKey in ctrs[key].properties) {
                let pname = ctrs[key].properties[propKey].propertyName; 
                if (
                    pname == "Product"
                    || pname == "Downhole LSD"
                    || pname == "Capacity"
                    || pname == "DTE"
                    || pname == "Volume (%)"
                    || pname == "Injection Avg"
                    || pname == "Injection Target"
                    || pname == "Last Device Communication"            
                )
                {
                    ctrs[key][pname] = ctrs[key].properties[propKey].propValue;
                }
            }
        }
        return ctrs;
    },
    getContainerStatus(state) {
        return state.containerStatus;
    },
    getContainersStatusFilter(state) {
        return state.containersStatusFilter;
    },
    getContainerProps(state) {
        return state.containerProps;
    },
    // getContainerStatus(state, id) {     
    // return state.containersStatus.find(x => x.containerId === id)   
    // },
};