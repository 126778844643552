export  default {
    setGroups(state, payload) {
        state.groups = payload;
      },
    setGroup(state, payload) {
        state.group = payload;
      },
    setResponseCode(state, payload) {
        state.responseCode = payload;
    },
    updateGroup(state, payload) {
      const group = state.groups.find(x=>x.groupId===payload.groupId);
      group.groupName = payload.groupName;
      group.active = payload.active;
  },
};