<template>
  <div class="aligncenter">
    <img :src="require('@/assets/r3mote_logo_lrg.png')" style="width:100%;"/>
    <h5>Reset Password</h5>
  </div>
  <form class="login-form" @submit.prevent="onSubmit">
    <DxTextBox class="tb" label="Username" mode="text" :show-clear-button="true" v-model:value="userName" :input-attr="inputAttr" />
    <DxTextBox class="tb" label="New Password" mode="text" :show-clear-button="true" v-model:value="resetPassword1" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>
    <DxTextBox class="tb" label="Confirm Password" mode="text" :show-clear-button="true" v-model:value="resetPassword2" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>
    <DxButton id="resetButton"
      text="Reset Password" 
      @click="resetPW()"
      styling-mode="contained"
      width={200px}
    />
  </form>
</template>

<script>

import { DxButton, DxButtonOptions } from 'devextreme-vue/button';
import DxTextBox from "devextreme-vue/text-box";
import notify from 'devextreme/ui/notify';
import { DxValidator, DxStringLengthRule } from 'devextreme-vue/validator';
import DxItem from "devextreme-vue/form";

export default {
created() {},
data() {
  return {
    userName: "",
    resetPassword1: "",
    resetPassword2: "",
    inputAttr: {
      mode: "password",
      inputAttr: {
        autocomplete: "off" //"new-password"
      }
    },
  }
},
components: {
  DxTextBox,
  DxValidator,
  DxStringLengthRule,
  DxButton,
},
computed: { },
methods: {
  async resetPW() {
    if (this.resetPassword1 === this.resetPassword2 && this.resetPassword1.length>=6 && this.userName.length>=3)
    {
      const pwReset = {
        userName : this.userName,
        newPassword : this.resetPassword1,
        uid : this.$route.query.id 
      }
      this.$store.dispatch('users/resetPassword', pwReset);
      notify("Password reset.", "success", 5000);
      this.$router.push({ name: 'login-form'});
    }
    else
    {
      notify("Reset Password error.  Ensure correct username, and passwords match.", "error", 5000);
    }
  }
},
}

</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }
  .tb{
    margin: 10px 0 30px 0px;
  }
  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
#resetButton {  
  background-color: #dc5500;
  // color: rgba($base-text-color, alpha($base-text-color) * 0.7); 
  color: black; 
  width: 100%;
  // border-color: #ffca51;  
}  
</style>
