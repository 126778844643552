<template>

  <div class="content-block dx-card" >
    <h2>Districts</h2>   <!-- ❯ = unicode U+276F  -->
  </div>

  <div class="content-block dx-card" id ="dataDiv">
    <DxDataGrid 
      class="dx-card wide-card" 
      :data-source="districts" 
      :key-expr="districtId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }" 
      :show-borders="true"
      :columns-auto-width="true" 
      :column-min-width="50"  
      :allow-column-resizing="true"
      :column-resizing-mode="columnResize"
      :allow-column-reordering="true"
      :filter-builder="filterBuilder" 
      :filter-value="filterValue"
      :style="{ 'max-height': datagridHeight }"
      no-data-text="No data."
      @selection-changed="onSelectionChanged" 
      @initialized="saveGridInstance"
    >
      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" />
      <DxExport :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxPager
        :show-info="true"
        :show-page-size-selector="true"
        :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true"
      />

      <DxToolbar>
        <DxItem location="before" template="addRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>

      <template #addRecordTemplate>
        <DxButton icon="add" @click="newRec" />
      </template>
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" @click="refreshData" />
      </template>
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" @click="removeFilter" />
      </template>

      <DxColumn data-field="districtName" caption="District Name" />
      <!-- <DxColumn data-field="districtId" caption="District">
        <DxLookup :data-source="districts" value-expr="districtId" display-expr="districtName" />
      </DxColumn> -->
    </DxDataGrid>
  </div>
  
  <DxLoadPanel
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onLoadPanelShown"
    :on-hidden="onLoadPanelHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
  <!-- :position="{ of: '#dataDiv' }" -->
  <!-- container="#dataDiv" -->

</template>

<script>

// import {DxToolbar} from 'devextreme-vue/toolbar';
// import {DxExport} from "devextreme-vue/chart";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxStateStoring,
  DxColumnFixing,
  DxPager,
  DxItem,
  DxLookup,
  DxToolbar,
  DxExport
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    // DxLookup,
    DxLoadPanel,
    DxButton,
    DxToolbar,
    DxItem,
    DxExport,
    DxColumnFixing,
    DxPager,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      columnResize: 'widget',
      dataGridInstance: null,
      loadingVisible: false,
      isLoadPanelVisible: false,
      datagridHeight: '40vh',
      //areas: [],
      currentOffice: null,
    };
  },
  computed: {
    districts() {
      return this.$store.getters["districts/getDistricts"];
    }
  },
  methods: {
    onLoadPanelShown() {
      setTimeout(() => { this.loadingVisible = false; }, 10000);
    },
    onLoadPanelHidden() {
      // this.XYZ = XYZ;
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight = (h-250) + 'px';
    },
    newRec(){
      this.$router.push({ name: 'district-details', params: {id: 0}});
    },
    removeFilter(){
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    refreshData(){
      this.loadPageData();
    },
    onSelectionChanged({ selectedRowsData }) {
      this.$router.push({ name: 'district-details', params: {id: selectedRowsData[0].districtId}});
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      try {
        await this.$store.dispatch("districts/loadDistricts", {forceRefresh: refresh});
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
      this.loadingVisible = false;
    },
  },
};
</script>

<style scoped>
  h2 {
    margin-left: 1em; 
  }
</style>
