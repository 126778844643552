export  default {
    setDistricts(state, payload) {
        state.districts = payload;
    },
    updateDistrict(state, payload) {
        const district = state.districts.find(x=>x.districtId===payload.districtId);
        district.districtName = payload.districtName;
    },
    addDistrict(state, payload) {
        state.districts.push(payload);
    },
    setCurrentDistrict(state, payload) {
        state.currentDistrict = payload;
    },
    setResponseCode(state, payload) {
        state.responseCode = payload;
    }
};