export default {
  async updateDeviceStatus(context, payload) {

    const newRequest = {
      deviceStatusId: payload.deviceStatusId,
      statusName: payload.statusName,
      defaultDeviceStatus: payload.defaultDeviceStatus
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceStatuses/${payload.deviceStatusId}`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceStatuses/${payload.deviceStatusId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateDeviceStatus', payload);
  },

  async addDeviceStatus(context, payload) {
    const newRequest = {
      deviceStatusId: payload.deviceStatusId,
      statusName: payload.statusName,
      defaultDeviceStatus: payload.defaultDeviceStatus
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceStatuses`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceStatuses`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.deviceStatusId = responseJson.deviceStatusId;
    context.commit('addDeviceStatus', payload);
  },

  async loadDeviceStatuses(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceStatuses`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceStatuses`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const deviceStatus = {
        deviceStatusId: responseJson[key].deviceStatusId,
        statusName: responseJson[key].statusName,
        defaultDeviceStatus: responseJson[key].defaultDeviceStatus,
        };

      responseData.push(deviceStatus);
        
    }
    context.commit('setDeviceStatuses', responseData)
  },

  async loadDeviceStatus(context, payload ) {

    const id = payload.id;

    let deviceStatus = {
      deviceStatusId: 0,
      statusName: "",
      defaultDeviceStatus: null
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/deviceStatuses/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/deviceStatuses/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      deviceStatus = {
        deviceStatusId: responseJson.deviceStatusId,
        statusName: responseJson.statusName,
        defaultDeviceStatus: responseJson.defaultDeviceStatus,
        };
    }
    context.commit('setCurrentDeviceStatus', deviceStatus)
  },
};
