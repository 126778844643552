<template>
  <div class="content-block dx-card" >
    <h2>Reporting ❯ Active Device List</h2>   <!-- ❯ = unicode U+276F  -->
  </div>

  <div class="content-block dx-card" id ="dataDiv">
  <!-- <div class="dx-card responsive-paddings" id ="dataDiv"> -->
  <!-- <div id ="dataDiv"> -->
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="activeDevices"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="columnResize"
      :allow-column-reordering="true"
      no-data-text="Loading ..."
      @initialized="saveGridInstance"
      :style="{ 'max-height': datagridHeight }"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="rep_active_devices"
      />
      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" />
      <DxExport :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxPager
        :show-info="true"
        :show-page-size-selector="true"
        :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true"
      />

      <DxToolbar>
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" hint="Refresh Data" @click="refreshData" />
      </template>
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>
      
    </DxDataGrid>
  </div>

  <DxLoadPanel
    :position="{ of: '#dataDiv' }"
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onShown"
    :on-hidden="onHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
</template>

<script>
import {DxToolbar} from 'devextreme-vue/toolbar';
import {DxExport} from "devextreme-vue/chart";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxStateStoring,
  DxColumnFixing,
  DxPager,
  DxItem
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import { stringifyQuery } from 'vue-router';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: 
  {
    DxToolbar,
    DxItem,
    //   DxToolbarItem,
    DxDataGrid,
    // DxColumn,
    DxPager,
    DxColumnFixing,
    DxStateStoring,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    // DxButton,
    // DxSelectBox,
    // DxDateBox,
    DxExport,
    DxLoadPanel
  },
  data() 
  {
    return {
      columnResize: 'widget',
      dataGridInstance: null,
      loadingVisible: false,
      isLoadPanelVisible: false,
      datagridHeight: '40vh',
      activeDevices: [],
    };
  },
  created() 
  {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  methods: 
  {
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("devices/loadActiveDevices");
        this.activeDevices = await this.$store.getters["devices/getActiveDevices"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      } 
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-250) + 'px';
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    removeFilter(){
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    refreshData(){
      this.loadPageData();
    },
  },
};
</script>

<style scoped>
  h2 {
    margin-left: 1em; 
  }
</style>
