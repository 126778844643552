<template>
  <div class="aligncenter">
    <img :src="require('@/assets/r3mote_logo_lrg.png')" style="width:100%;"/>
    <!-- <h5>Reset Password</h5> -->
    <p>Please enter your user name.<br><br>The associated email address will be sent a link to reset your password.</p>
  </div>

  <form class="login-form" @submit.prevent="onSubmit">
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="userNameReset"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: 'User Name', mode: 'email' }"
      >
        <dx-required-rule message="User Name is required" />
        <dx-email-rule message="User Name is invalid" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>
      <dx-item>
        <template #default>
          <div class="link">
            <router-link to="/login-form">Return to Sign In</router-link>
          </div>
        </template>
      </dx-item>
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">Request Password Reset</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";

import notify from 'devextreme/ui/notify';

export default {
created() {},
data() {
  return {
    formData : { userName: "" },
    loading : false,
  }
},
components: {
  DxLoadIndicator,
  DxForm,
  DxEmailRule,
  DxRequiredRule,
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
},
computed: { },
methods: {
  async onSubmit() {
      this.loading = true;
      //types ['error', 'info', 'success', 'warning']
      this.$store.dispatch('users/requestPasswordReset', { userName: this.formData.userNameReset});
      notify("Password reset requested.", "success", 5000);
      this.$router.push({ name: 'login-form'});
    }
},
}

</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
</style>
