<template>
  <div >
    <DxBox>
      <DxItem :ratio="1">
        <DxButton class="requestBtn" text="Request Calibration"  />
      </DxItem>
      <DxItem :ratio="1">
        Request a sensor calibration comparing data from a physical measurement with calculated properties.
      </DxItem>
    </DxBox>
    <hr style="height:1px;border:1px solid #444;"/>
    <DxBox>
      <DxItem :ratio="1">
        <DxButton class="requestBtn" text="Change Product"  />
      </DxItem>
      <DxItem :ratio="1">
        Request a product/chemical change.
      </DxItem>
    </DxBox>
    <hr style="height:1px;border:1px solid #444;"/>
  </div>
</template>

<script>

// import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxBox, DxItem } from 'devextreme-vue/box';

export default {
  components: {
    // DxTextBox,
    DxButton,
    DxBox,
    DxItem,
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadPageData()
  },
  data() {
    return {
    };
  },
  computed: {

  },
  methods: {
    loadPageData() {
    },

  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.7);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .requestBtn {  
    /* color: rgb(65, 65, 65);   */
    color: black;  
    background-color: rgb(255, 115, 0);  
    margin: 0 20px 20px 0;
    /* border-color: rgb(100, 100, 100);
    border-radius: 6px; */
    /* padding: 20px; */
  } 
  .requestBtn.dx-state-hover {  
    /* color: black;   */
    background-color: rgb(223, 102, 4);  
  }  
</style>
