export default {
  async loadActiveDevices(context, payload ) {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/activedevices`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        //error, NOT ok
        const error = new Error(responseData.message || "Failed to fetch.");
        throw error;
    }
    const responseJson = await response.json();
    const responseData = [];

    for (const key in responseJson) {
        const device = {
            customerName: responseJson[key].customerName,
            operatorName: responseJson[key].operatorName,
            areaName: responseJson[key].areaName,
            Name: responseJson[key].containerName,
            latitude: responseJson[key].latitude,
            longitude: responseJson[key].longitude,
            product: responseJson[key].product,
            downholeLSD: responseJson[key].downholeLSD,
            imei: responseJson[key].imei,
            statusName: responseJson[key].statusName,
            district: responseJson[key].district,
            office: responseJson[key].office,
            satellite: responseJson[key].satellite

        };
        responseData.push(device);
    }
    context.commit('setActiveDevices', responseData)
  },
};