export  default {
    getProperties(state) {
        return state.properties;
    },
    getPropertyLists(state) {
        return state.propertyLists;
    },
    getCurrentPropertyList(state) {
        return state.propertyList;
    },
    getResponseCode(state) {
        return state.responseCode;
    }
};