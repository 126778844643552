export default {
  async updateRegion(context, payload) {

    // copy from template or areas
  },

  async loadRegions(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/regions`;
    // var url = `https://r3backendprd.azurewebsites.net/api/regions`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const region = {
        regionId: responseJson[key].regionId,
        countryId: responseJson[key].countryId,
        regionName: responseJson[key].regionName,
        abbrv: responseJson[key].abbrv,
        default: responseJson[key].default,
        };

      responseData.push(region);
        
    }
    context.commit('setRegions', responseData)
  },
  
};
