<template>
  <!-- unicode U+276F : ❯ -->
  <h2 class="content-block">Containers Master</h2>
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="containers"
      :key-expr="containerId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="true"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="nextColumn"
      :allow-column-reordering="true"
      style="max-height: 80vh"
      @selection-changed="masterSelectionChanged"        
      @initialized="saveGridInstance"
    >
    <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="rep_container_grid"
      />

    <DxToolbar>
      <DxItem location="before" template="addRecordTemplate" />
      <DxItem location="after" template="refreshRecordTemplate" />
      <DxItem location="after" template="removeFilterRecordTemplate" />
      <DxItem location="after" name="columnChooserButton" />
    </DxToolbar>

    <template #addRecordTemplate>
      <DxButton icon="add" @click="addRecord" />
    </template>
    <template #refreshRecordTemplate>
      <DxButton icon="refresh" @click="refreshData" />
    </template>
    <template #removeFilterRecordTemplate>
      <DxButton icon="filter" @click="removeFilter" />
    </template>

    <DxLoadPanel :enabled="true" />
    <DxColumnChooser :enabled="true" mode="select" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxScrolling mode="virtual" />
    
    <DxColumn data-field="operator" caption="Operator" /> 
    <DxColumn data-field="area" caption="Area" />
    <DxColumn data-field="serialnum" caption="Device" />
    <DxColumn data-field="containerName" caption="Name" />
    <DxColumn data-field="division" caption="Division" />
    <DxColumn data-field="containerType" caption="Type" />
    <DxColumn data-field="properties[10].propValue" caption="Product" alignment='left'  :visible="true"/>   
    <DxColumn data-field="properties[17].propValue" caption="Downhole LSD" alignment='left'  :visible="true"/>
    

  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxLoadPanel,
  DxToolbar,
  DxItem,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxItem,
    DxButton,
    DxStateStoring,
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      dataGridInstance: null,
      containers : [],
    };
  },
  // computed: {
  //   containers() {
  //     return this.$store.getters["containers/getContainersStatus"];
  //   },
  // },
  methods: {
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    addRecord(){
      // this.$router.push({ name: 'containers-add', params: {id: e.selectedRowsData[0].containerId}});
      this.$router.push({ name: 'containers-add'});
    },
    removeFilter(){
      this.dataGridInstance.clearFilter("row");
    },
    refreshData(){
      this.loadPageData();
    },
    masterSelectionChanged(e) {
      this.$router.push({ name: 'containers-details', params: {id: e.selectedRowsData[0].containerId}});
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainersStatus", {forceRefresh: refresh,});
        this.containers = this.$store.getters["containers/getContainersStatus"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
      
    },
  },
};

</script>

<style scoped>
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
</style>
