<template>
  <!-- unicode U+276F : ❯ -->
  <h2 class="content-block">Reporting ❯ Tank History</h2>
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="containers"
      :key-expr="containerId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="true"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="widget"
      :allow-column-reordering="true"
      style="max-height: 80vh"
      @selection-changed="masterSelectionChanged"        
      @initialized="saveGridInstance"
    >

    <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="rep_history_container_grid_v_oper"
      />

    <DxToolbar>
      <DxItem location="after" template="refreshRecordTemplate" hint="Refresh Data" />
      <DxItem location="after" template="removeFilterRecordTemplate" />
      <DxItem location="after" name="columnChooserButton" />
    </DxToolbar>

    <template #refreshRecordTemplate>
      <DxButton icon="refresh" @click="refreshData" />
    </template>
    <template #removeFilterRecordTemplate>
      <DxButton icon="filter" @click="removeFilter" />
    </template>

    <DxLoadPanel :enabled="true" />
    <DxColumnChooser :enabled="true" mode="select" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxScrolling mode="virtual" />

    <DxColumn data-field="operator" caption="Operator" min-width="125"/> 
    <DxColumn data-field="area" caption="Area" min-width="125"/>
    <DxColumn data-field="serialnum" caption="Device" :visible="false" min-width="125"/>
    <DxColumn data-field="containerName" caption="Name" :allow-reordering="true" min-width="150"/>
    <DxColumn data-field="Downhole LSD" caption="Downhole LSD" alignment='left' min-width="150" :visible="true"/>      
    <DxColumn data-field="Product" caption="Product" alignment='left' min-width="100" :visible="true"/>         
    
    
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxLoadPanel,
  DxToolbar,
  DxItem,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxItem,
    DxButton,
    DxStateStoring,
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      dataGridInstance: null,
    };
  },
  computed: {
    containers() {
      // return this.$store.getters["containers/getContainersStatus"];
      return this.$store.getters["containers/getContainersFlatProperties_ViewOperator"];
    },
    properties(index){
      return this.containers[index].properties;
    },
  },
  methods: {
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    removeFilter(){
      this.dataGridInstance.clearFilter("row");
    },
    refreshData(){
      this.loadPageData();
    },
    masterSelectionChanged(e) {
      this.$router.push({ name: 'reporting-containers-history-id', params: {id: e.selectedRowsData[0].containerId}});
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainersStatus", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
    },
  },
};

</script>

<style scoped>
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
</style>
