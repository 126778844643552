
import common from '../common.js';

export default {

  async loadMenu(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/menu`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    const isMobile = common.testMobile();

    for (const key in responseJson) {
      
      const menu_item = {
        menuGroup: responseJson[key].menuGroup,
        text: responseJson[key].itemName,
        path: responseJson[key].itemPath,
        availMobile: responseJson[key].availMobile,
        };
      
      if (!isMobile || (isMobile && menu_item.availMobile)){
        responseData.push(menu_item);
      }
      // responseData.push(menu_item);
    }
    
    var menu = [{
      text: "Home",
      path: "/home",
      icon: "home"
    }]
    if (responseData.filter(x => x.menuGroup === 'Reporting').length > 0) {
      let menuItemReporting = {
        text: "Reporting",
        icon: "chart",
        items: []
      };
      for (const i in responseData) {
        if (responseData[i].menuGroup === 'Reporting')
          { 
            menuItemReporting.items.push(responseData[i]); 
          }
      }
      menu.push(menuItemReporting);
    }
    if (responseData.filter(x => x.menuGroup === 'Master').length > 0) {
      let menuItemMaster = {
        text: "Admin",
        icon: "folder",
        items: []
      };
        for (const i in responseData) {
          if (responseData[i].menuGroup === 'Master')
            menuItemMaster.items.push(responseData[i]); 
      }
      menu.push(menuItemMaster);
    }
    if (responseData.filter(x => x.menuGroup === 'Logs').length > 0) {
      let menuItemLog = {
        text: "Logs",
        icon: "pasteplaintext",
        items: []
      };
        for (const i in responseData) {
          if (responseData[i].menuGroup === 'Logs')
            menuItemLog.items.push(responseData[i]); 
      }
      menu.push(menuItemLog);
    }

    await context.commit('setMenu', menu)
  },
}
