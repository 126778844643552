<template>
  <div >
    <!-- History, Injection w/Target, last 5 fills, 24/48/72 -->

    <DxScrollView :width="auto" :height="440" direction="both">  <!-- 'horizontal' | 'vertical' -->
      
      <div class="divGroup" id ="containerInfo">
        <DxChart class="chartGroup" :data-source="containerVolumeHistory" >
          <!-- <DxZoomAndPan argument-axis="both" value-axis="none"/> -->
          <DxCommonSeriesSettings
            argument-field="historyDate"
            value-field="propValue"
            type="line"
            show-in-legend="false"
            color="#9090FF"
          />
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="bottom"
            visible="true"
          />
          <DxSeriesTemplate name-field="containerName" visible="false" />
          <DxExport :enabled="true" />
          <DxTitle text="Volume History">
            <!-- <DxSubtitle text="(Volume L)" /> -->
          </DxTitle>
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
          <DxValueAxis show-zero="true" />  
        </DxChart>
      </div>

      <div class="divGroup" id ="containerInfo">
        <DxChart class="chartGroup" :data-source="containerVolumeHistoryDetail" >
          <!-- <DxZoomAndPan argument-axis="both" value-axis="none"/> -->
          <DxCommonSeriesSettings
            argument-field="historyDate"
            value-field="propValue"
            type="line"
            show-in-legend="false"
            color="#9090FF"
          />
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="bottom"
            visible="true"
          />
          <DxSeriesTemplate name-field="containerName" visible="false" />
          <DxExport :enabled="true" />
          <DxTitle text="Volume History Detail">
            <!-- <DxSubtitle text="(Volume L)" /> -->
          </DxTitle>
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
          <DxValueAxis show-zero="true" />  
        </DxChart>
      </div>

      <div class="divGroup" id ="containerInfo">
        <!-- INJECTION -->
        <DxChart class="chartGroup" :data-source="containerInjectionHistory" >
          <DxCommonSeriesSettings
            argument-field="historyDate"
            value-field="propValue"
            type="bar"
            show-in-legend="false"
            color="#66b5ba"
          />
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="bottom"
            visible="true"
          />
          <DxSeriesTemplate name-field="containerName" visible="false" />
          <DxExport :enabled="true" />
          <DxTitle text="Injection History">
            <!-- <DxSubtitle text="(Injection)" /> -->
          </DxTitle>
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
          <DxValueAxis show-zero="true">  
            <DxStrip :start-value="targetInjection" :end-value="targetInjection*1.03" color="#E6F542" />
          </DxValueAxis>
        </DxChart>
      </div>
      <div class="divGroup" id ="containerInfo">
        <!-- FILLS -->
        <DxChart class="chartGroup" :data-source="containerFillHistory" >
          <!-- <DxCommonSeriesSettings
            argument-field="days"
            value-field="fillAmt"
            type="bar"
            show-in-legend="false"
            color="#66b5ba"
          /> -->
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="bottom"
            visible="false"
          />
          <!-- <DxSeriesTemplate name-field="containerName" visible="false" /> -->
          <DxSeries
            name="Fills"
            argument-field="days"
            value-field="fillAmt"
            type="bar"
            color="#ffaa66"
          />
          
          <DxExport :enabled="true" />
          <DxTitle text="Fill History">
            <!-- <DxSubtitle text="()" /> -->
          </DxTitle>
        </DxChart>
      </div>
    </DxScrollView>
  </div>
  
</template>

<script>

import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxChart, 
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxSeries,
  // DxZoomAndPan,
  DxStrip
} from "devextreme-vue/chart";

export default {
  components: {
    DxScrollView,
    DxChart, 
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxLegend,
    DxTitle,
    // DxSubtitle,
    DxTooltip,
    DxValueAxis,
    DxSeries,
    DxExport,
    DxStrip
  },
  props: ['containerId', 'container'],
  created() { 
    
  },
  data() {
    return {
      searchVal: "",
      ctrlOver: ""
    };
  },
  computed: {
    targetInjection(){
      return this.container['Injection Target'];
      // return 2000;
    },
    containerVolumeHistory(){
      const currentHistoryType = 90;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 

    containerVolumeHistoryDetail(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerHistoryDetail"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);
      
      console.log("DETAIL", data);

      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate >= dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate,
            // historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2) + " " + data[key].historyDate.getDate().slice(-8),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      console.log("DETAIL POST", volumeHist);
      return volumeHist;
    }, 

    containerInjectionHistory(){
      const currentHistoryType = 90;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Injection Avg" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }      
      return volumeHist;
    }, 
    containerFillHistory(){
      const currentHistoryType = 90;
      const fillHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);
      for (let i = 0; i < 60; i++) {
        // const newHist = { date: dateCriteria, fillAmt: 0 } //there is a problem with this line, by ref not val
        const newHist = { days: i, fillAmt: 0 }
        if (Math.floor(Math.random() * 8) == 0) //random number from 0-5
        {
          newHist.fillAmt = this.container['Capacity'] * (Math.floor(Math.random() * 85)/100)
        }
        fillHist.push(newHist)
        dateCriteria.setDate(dateCriteria.getDate()+1);
        
      }
      
      // console.log("FILLS",fillHist)
      return fillHist;
    }, 
  },
  watch: {
    async containerId (){
      // console.log("ANALYTICS WATCHER");
      this.loadHistory()
      // await this.$store.dispatch("containers/loadContainerStatus", this.containerId )
      // this.container = this.$store.getters["containers/getContainerStatus"];
    },
  },
  methods: {
    loadHistory(){
      if (this.container.containerId != null && this.container.containerId !== undefined && this.container.containerId > 0)
      {
        this.$store.dispatch("containers/loadContainerHistory", this.container.containerId);
        this.$store.dispatch("containers/loadContainerHistoryDetail", this.container.containerId);
        
        // console.log(this.$store.getters["containers/getContainerHistory"]);
        // this.showHistory = 1;
      }
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} L</span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .chartGroup {
    /* width: 85%; */
    height: 300px;
  }
</style>
