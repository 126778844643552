import VueCookies from 'vue-cookies'; 
export default {
    getToken(state) {
        return VueCookies.get('token');
        // return state.token;
    },
    getExpiration(state) {
        return state.expiration;
    },
    getUsername(state) {
        return state.username;
    },
    getTokenDetails(state) {
        // var token = context.rootGetters['auth/getToken']
        
        // var base64Url = token.split('.')[1];
        // var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        // var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        //     }).join(''));
    
        // return JSON.parse(jsonPayload);
    }
};