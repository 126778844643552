import common from '../common.js';

export default {
  async loadGroups(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/groups`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const group = {
        groupId: responseJson[key].groupId,
        groupName: responseJson[key].groupName,
        active: responseJson[key].active,
        };

      responseData.push(group);
        
    }
    context.commit('setGroups', responseData)
  },

  async loadGroup(context, payload ) {

    const id = payload.id;

    let group = {
      groupId: 0,
      groupName: "",
      active: true,
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/groups/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();

      group = {
        groupId: responseJson.groupId,
        groupName: responseJson.groupName,
        active: responseJson.active,
      };
    }
    context.commit('setGroup', group)
  },
  
  async addGroup(context, payload) { 
    const newRequest = {
      groupId: 0,
      groupName: payload.groupName,
      active: true,
      admin: false
    };
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/groups`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });
    
  },

  async updateGroup(context, payload) {
    const newRequest = {
      groupId: payload.groupId,
      groupName: payload.groupName,
      active: payload.active,
      admin: false
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/groups/${payload.groupId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');  
      throw error;
    }
    context.commit('updateGroup', payload);
  },
};
