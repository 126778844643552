export default {
 
  async loadPermissionAreas(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissionareas`;
        
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const permarea = {
        permAreaId: responseJson[key].permAreaId,
        groupId: responseJson[key].groupId,
        areaId: responseJson[key].areaId,
        };

      responseData.push(permarea);
        
    }
    context.commit('setPermAreas', responseData)
  },

  async loadPermissionArea(context, payload ) {

    const id = payload.id;

    let permarea = {
      permAreaId: 0,
      groupid: 0,
      areaid: 0,
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/permissionareas/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      permarea = {
        permAreaId: responseJson.permAreaId,
        groupId: responseJson.groupId,
        areaId: responseJson.areaId,
        };
    }
    context.commit('setPermArea', permarea)
  },
};
