export default {
  async updateOperator(context, payload) {

    const newRequest = {
      operatorId: payload.operatorId,
      operatorName: payload.operatorName,
      operatorExternal: payload.operatorExternal
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/operators/${payload.operatorId}`;
    // var url = `https://r3backendprd.azurewebsites.net/api/operators/${payload.operatorId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateOperator', payload);
  },

  async addOperator(context, payload) {
    const newRequest = {
      operatorId: payload.operatorId,
      operatorName: payload.operatorName,
      operatorExternal: payload.operatorExternal
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/operators`;
    // var url = `https://r3backendprd.azurewebsites.net/api/operators`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.operatorId = responseJson.operatorId;
    context.commit('addOperator', payload);
  },

  async loadOperators(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/operators`;
        
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];
    
    for (const key in responseJson) {
      
      const operator = {
        operatorId: responseJson[key].operatorId,
        operatorName: responseJson[key].operatorName,
        operatorExternal: responseJson[key].operatorExternal
        };

      responseData.push(operator);
        
    }
    context.commit('setOperators', responseData)
  },

  async loadOperator(context, payload ) {

    const id = payload.id;

    let operator = {
      operatorId: 0,
      operatorName: "",
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/operators/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/operators/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      operator = {
        operatorId: responseJson.operatorId,
        operatorName: responseJson.operatorName,
        operatorExternal: responseJson.operatorExternal
        };
    }
    context.commit('setCurrentOperator', operator)
  },

  async loadOperatorsAreas(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/operators/areas`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const operArea = {
        operatorId: responseJson[key].operatorId,
        operatorName: responseJson[key].operatorName,
        areaId: responseJson[key].areaId,
        areaName: responseJson[key].areaName,
        };

      responseData.push(operArea);
        
    }
    context.commit('setOperatorsAreas', responseData)
  },

};
