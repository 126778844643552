import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      username: null,
      //token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoianVzdGluQHIzbW90ZS5pbyIsIklkIjoiMiIsIkNJZCI6IjEiLCJqdGkiOiI3OTgxYmI4MS1mMzU3LTRiMDQtOWE4ZS0zMTc2ZmU5ZGRmZWYiLCJleHAiOjE2NzMzMDUwMzYsImlzcyI6Imh0dHBzOi8vcjNtb3RlLmlvIiwiYXVkIjoiaHR0cHM6Ly9yM21vdGUuaW8vYXBpIn0.eIwwq28CUp6mbC310rPfr928HKQ0m3upWjBKysltOiA",
      token: "",
      expiration: null,
      
    };
  },
  mutations,
  actions,
  getters,
};
