<template>
  <h2 class="content-block">Device Statuses</h2>

  <DxDataGrid 
      class="dx-card wide-card" 
      :data-source="deviceStatuses" 
      :selection="{ mode: 'single' }" 
      :show-borders="true"
      :columns-auto-width="true" 
      :column-min-width="50" 
      :hover-state-enabled="true" 
      :key-expr="deviceStatusId"
      @selection-changed="onSelectionChanged" 
      :filter-builder="filterBuilder" 
      :filter-value="filterValue"
      :column-resizing-mode="nextColumn" 
      :allow-column-resizing="true" 
      style="max-height: 80vh"
      @initialized="saveGridInstance"
    >

    <DxToolbar>
      <DxItem location="before" template="addRecordTemplate" />
      <DxItem location="after" template="refreshRecordTemplate" />
      <DxItem location="after" template="removeFilterRecordTemplate" />
      <DxItem location="after" name="columnChooserButton" />
    </DxToolbar>

    <template #addRecordTemplate>
      <DxButton icon="add" @click="newRec" />
    </template>
    <template #refreshRecordTemplate>
      <DxButton icon="refresh" @click="refreshData" />
    </template>
    <template #removeFilterRecordTemplate>
      <DxButton icon="filter" @click="removeFilter" />
    </template>

    <DxLoadPanel :enabled="true" />
    <DxColumnChooser :enabled="true" mode="select" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxScrolling mode="virtual" />

    <DxColumn data-field="statusName" caption="Name" />
    <DxColumn data-field="defaultDeviceStatus" caption="Is Default Status?" />
  </DxDataGrid>

  
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLoadPanel,
  DxToolbar,
  DxItem
} from "devextreme-vue/data-grid";

import { DxButton } from 'devextreme-vue/button';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxButton,
    DxToolbar,
    DxItem
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      dataGridInstance: null,
      currentDeviceStatus: null,
    };
  },
  computed: {
    deviceStatuses() {
      return this.$store.getters["deviceStatuses/getDeviceStatuses"];
    },
  },
  methods: {
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    newRec(){
      this.$router.push({ name: 'deviceStatus-details', params: {id: 0}});
    },
    removeFilter(){
      this.dataGridInstance.clearFilter("row");
    },
    refreshData(){
      this.loadPageData();
    },
    onSelectionChanged({ selectedRowsData }) {
      this.$router.push({ name: 'deviceStatus-details', params: {id: selectedRowsData[0].deviceStatusId}});
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("deviceStatuses/loadDeviceStatuses", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }

    },
  },
};
</script>

<style scoped>

</style>
