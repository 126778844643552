export  default {
    getOffices(state) {
        return state.offices;
    },
    getCurrentOffice(state) {
        return state.currentOffice;
    },
    getResponseCode(state) {
        return state.responseCode;
    },
};