export  default {
    setManufacturers(state, payload) {
        state.manufacturers = payload;
      },
    updateManufacturer(state, payload) {
        const manufacturer = state.manufacturers.find(x=>x.manufacturerId===payload.manufacturerId);
        manufacturer.manufacturerName = payload.manufacturerName;
      },
    addManufacturer(state, payload) {
        state.manufacturers.push(payload);
      },
    setCurrentManufacturer(state, payload) {
        state.currentManufacturer = payload;
      },
};