<template>
  
  <h2 class="content-block">Containers : Add New</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>
  
  <div class="content-block dx-card responsive-paddings">
    <h6>Container Name : </h6>
    <DxTextBox  v-model:value="newContainer"/>

    <h6>Device SerialNum : </h6>
    <DxTextBox  v-model:value="newDevice"/>

    <h6>Operator : </h6>
    <DxSelectBox :data-source="operators" value-expr="operatorName" display-expr="operatorName" v-model:value="newOper" @value-changed="operatorChanged"/>

    <h6>Area : </h6>
    <DxCheckBox  
      text="View All"
      :value="allAreas"
      :enable-three-state-behavior="false"
      @value-changed="allAreasChanged"
    />
    <DxSelectBox v-if="allAreas==true" :data-source="areas" value-expr="areaName" display-expr="areaName" v-model:value="newArea"/>
    <DxSelectBox v-if="allAreas==false" :data-source="existingOperatorAreas" value-expr="areaName" display-expr="areaName" v-model:value="newArea"/>

    <DxDataGrid
      :data-source="newProperties"
      :show-borders="true"
      
    >
    <!-- key-expr="ID" -->
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        :select-text-on-edit-start="true"
        :start-edit-action="click"
        mode="cell"
      />
      <!-- mode="batch" -->
      <DxPaging :enabled="false"/>
      
      <DxColumn
        data-field="propertyId"
        caption="Property"
      >
        <DxLookup
          :data-source="properties"
          value-expr="propertyId"
          display-expr="propertyName"
        />
      </DxColumn>
      
      <DxColumn data-field="propValue" caption="Value"/>

    </DxDataGrid>

    <DxButton class="btn" text="Create New Site" @click="createSite()"/>
    <!-- <DxButton class="btn" text="Create New Site" @click="setProperties(1510)"/> -->
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxTextArea
        :height="140"
        :read-only="true"
        v-model:value="newResponse"
      />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from 'devextreme-vue/button';
import DxTextArea from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxSelectBox,
    DxTextBox,
    DxButton,
    DxTextArea,
    DxCheckBox,    
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxToolbar,
    DxItem
  },
  data() {
    return {
      allAreas: false,
      areas: null,
      operators: null,
      groups: null,
      operAreas: null,
      oa: null,
      perm: null,
      newContainer: "",
      newDevice: "",
      newOper: "",
      newArea: "",
      result: null,
      newResponse: null,
      properties : [],
      // newProperties: [{PropertyId: null, PropertyName:null}],
      newProperties: [],
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
    }
  },
  created() {
    this.loadPageData();
  },
  computed: {
    existingOperatorAreas(){
      return this.$store.getters["operators/getOperatorsAreas"].filter(x => x.operatorName == this.newOper);
    }
  },
  methods: {
    async createSite(){
      const newSite= {
        containerName: this.newContainer.trim(),
        deviceSerial: this.newDevice.trim(),
        operatorName: this.newOper.trim(),
        areaName: this.newArea.trim()
      }
      
      await this.$store.dispatch('utils/addSiteOtodata', newSite);
      this.$emit('recordUpdated');
      this.result = await this.$store.getters["utils/getAddSiteResults"];
      if (this.result.recordsAffected != 0)
      {
        this.newResponse = "Record created. (" + this.result.recordsAffected + ")\n" + new Date().toISOString() + "\n" + this.result.message;
        this.setProperties(this.result.recordsAffected);
      }
      else
      {
        this.newResponse = "Record not created. " + new Date().toISOString() + "\n" + this.result.message.replaceAll(';','\r\n');
        return;
      }
    },
    
    async setProperties(newContainerId){
      
      await this.$store.dispatch('containers/loadContainerProperties', newContainerId);
      const props = await this.$store.getters["containers/getContainerProps"];
      
      let newProps=[];
      for(let i = 0;i < this.newProperties.length;i++)
      {
        let newCPA = props.find(data => data.propertyId==this.newProperties[i].propertyId);
        let newProp = {
          containerId: newContainerId,
          containerPropId: newCPA.containerPropId,
          critical: null,
          marginal: null,
          propValue: this.newProperties[i].propValue,
          propertyId: this.newProperties[i].propertyId,
          propertySort: 0
        };
        newProps.push(newProp);
      }
      
      await this.$store.dispatch('containers/updateContainerProperties', newProps);
    },
    operatorChanged(e) {
      this.newArea = null;
    },
    allAreasChanged(e) {
      this.allAreas = e.value;
      this.newArea = null;
    },
    groupFieldOptions() {
      return {
        items: this.groups,
        valueExpr: "groupId",
        displayExpr: "groupName"
      }
    },
    areaFieldOptions() {
      return {
        items: this.areas,
        valueExpr: "areaId",
        displayExpr: "areaName"
      }
    },
    operatorFieldOptions() {
      return {
        items: this.operators,
        valueExpr: "operatorId",
        displayExpr: "operatorName"
      }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("areas/loadAreas");
        await this.$store.dispatch("operators/loadOperators");
        await this.$store.dispatch("operators/loadOperatorsAreas");
        await this.$store.dispatch("groups/loadGroups");
        await this.$store.dispatch("properties/loadProperties");
        this.areas = this.$store.getters["areas/getAreas"];
        this.operators = this.$store.getters["operators/getOperators"];
        this.groups = this.$store.getters["groups/getGroups"];
        this.properties = this.$store.getters["properties/getProperties"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.fld{
  margin: 20px 0 0 2px;
}
.btn{
  margin: 50px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}
</style>

