
// this is the menu on the side-nav-menu.
// the path values refer to routes set up in routers.js and will not match any folder/file structure.

// dynamic menu per user starter:
// https://supportcenter.devexpress.com/ticket/details/t834258/how-to-populate-navigation-item-dynamically-from-axios

export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  // {
  //   // text: "Favorites",
  //   // icon: "favorites",
  //   text: "User",
  //   icon: "user",
  //   items: [
  //     {
  //       text: "Profile",
  //       path: "/profile"
  //     },
  //     {
  //       text: "Preferences",
  //       path: "/pref"
  //     }
  //   ]
  // },
  {
    text: "Reporting",
    icon: "chart",
    items: [
      {
        text: "Tank Status",
        path: "/reporting/containers-status"
      },
      {
        text: "Tank History",
        path: "/reporting/containers-history"
      }
    ]
  },
  // {
  //   text: "Master Data",
  //   icon: "folder",
  //   items: [
  //     {
  //       text: "Areas",
  //       path: "/areas",
  //     },
  //     {
  //       text: "Contacts",
  //       path: "/md2"
  //     },
  //     {
  //       text: "Tanks",
  //       path: "/md3"
  //     },
  //     {
  //       text: "Container Types",
  //       path: "/containertypes"
  //     },
  //     {
  //       text: "Countries",
  //       path: "/md4"
  //     },
  //     {
  //       text: "Customers",
  //       path: "/md5"
  //     },
  //     {
  //       text: "Device Status",
  //       path: "/deviceStatuses"
  //     },
  //     {
  //       text: "Device Types",
  //       path: "/devicetypes"
  //     },
  //     {
  //       text: "Divisions",
  //       path: "/divisions"
  //     },
  //     {
  //       text: "Groups",
  //       path: "/md9"
  //     },
  //     {
  //       text: "Manufacturers",
  //       path: "/manufacturers"
  //     },
  //     {
  //       text: "Operators",
  //       path: "/operators"
  //     },
  //     {
  //       text: "Properties",
  //       path: "/md11"
  //     },
  //     {
  //       text: "Regions",
  //       path: "/md12"
  //     },
  //     {
  //       text: "Sensors",
  //       path: "/md13"
  //     }
  //   ]
  // }
];
