export  default {
    setDeviceTypes(state, payload) {
        state.deviceTypes = payload;
      },
    updateDeviceType(state, payload) {
        const deviceType = state.deviceTypes.find(x=>x.deviceTypeId===payload.deviceTypeId);
        deviceType.deviceTypeName = payload.deviceTypeName;
      },
    addDeviceType(state, payload) {
        state.deviceTypes.push(payload);
      },
    setCurrentDeviceType(state, payload) {
        state.currentDeviceType = payload;
      },
};