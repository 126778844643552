<template>
  <DxDataGrid 
      class="dx-card wide-card" 
      :data-source="groups" 
      :show-borders="true"
      :columns-auto-width="true" 
      :column-min-width="50" 
      :hover-state-enabled="true" 
      key-expr="groupId"
      :column-resizing-mode="nextColumn" 
      :allow-column-resizing="true" 
      style="max-height: 80vh"
      @initialized="saveGridInstance"
      :columns="['groupName']"
    >
    <!-- :key-expr="groupId" -->
    <!-- :selected-row-keys="selectedItemKeys"
      @selection-changed="selectionChanged" -->
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxSelection mode="multiple" :allow-select-all="false"/>
      <DxSorting mode="none"/>
      <DxPaging :enabled="false" />
  </DxDataGrid>
</template>

<script>

import {
  DxDataGrid,
  DxSelection,
  DxSorting,
  DxPaging,
  DxFilterRow,
  DxHeaderFilter
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxSorting,
    DxPaging,
    DxFilterRow,
    DxHeaderFilter
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      selectedItemKeys: [],
      selectionChanged: (data) => {
        // this.selectedItemKeys = data.selectedRowKeys;
        // console.log("SELECTED KEYS",data.selectedRowKeys);
      },
      // user: null,
      // groups: [],
      // userGroups: [],
    };
  },
  computed: {
    isNewRec(){
      return (this.currentRecordId==0)
    },
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    user() {
      return this.$store.getters["users/getUser"];
    },
    groups() {
      return this.$store.getters["groups/getGroups"];
    },
    userGroups() {
      return this.$store.getters["users/getUserGroups"];
    },
  },
  methods: {
    saveGroupMembership(){
      const permKeys = this.dataGridInstance.getSelectedRowKeys();
      let i = 0;
      //for any selected row keys
      while (i < permKeys.length)
      {
        //if that group id in the original array
        let j = this.userGroups.findIndex((data)=>data.groupId == permKeys[i]);
        if(j >= 0)
        {
          //do nothing
        }
        else
        {
          //add the perm
          this.$store.dispatch("users/addUserGroup", { userGroupId: 0, groupId: permKeys[i], userId: this.currentRecordId });
        }
        i++;
      }
      i=0;
      while (i < this.userGroups.length)
      {
        //if that group is in the new(selected) array
        if(permKeys.includes(this.userGroups[i].groupId))
        {
          //do nothing
        }
        else
        {
          //remove the perm
          this.$store.dispatch("users/deleteUserGroup", { userGroupId: this.userGroups[i].userGroupId});          
        }
        i++;
      }
    },
    setGridMembership(){      
      let i = 0;
      while (i < this.groups.length)
      {
        for (const ug in this.userGroups)
        {
          if (this.groups[i].groupId == this.userGroups[ug].groupId)
          {
            this.dataGridInstance.selectRows(this.groups[i].groupId, true);
          }
        }
        i++;
      }
      // let membership = this.userGroups.map(a=>a.groupId);
      // let i = 0;
      // while (i < this.groups.length)
      // {
      //   if (membership.findIndex((data)=>data.groupId == this.groups[i].groupId) >= 0)
      //   {
      //     console.log ("FOUNDIR");
      //   }
      //   if (membership.includes(this.groups[i].groupId))
      //   {
      //     this.dataGridInstance.selectRowsByIndexes(i, true);
      //   }
      //   i++;
      // }

    },

    refreshData(){
      this.loadPageData();
    },

    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },

    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("users/loadUser", { id: this.currentRecordId });
        await this.$store.dispatch("groups/loadGroups");
        await this.$store.dispatch("users/loadUserGroups", { id: this.currentRecordId });
        // this.user = this.$store.getters["users/getUser"];
        // this.groups = this.$store.getters["groups/getGroups"];
        // this.userGroups = this.$store.getters["users/getUserGroups"];
        this.setGridMembership();

        // var d = new Date();
        // var datestring =  d.getFullYear() + "-" + d.getDate()  + "-" + (d.getMonth()+1) + "T" + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        // console.log(datestring);

      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.tb{
  margin: 10px 0 30px 0px;
}
</style>
