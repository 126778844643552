import store from './store/index.js';

const defaultUser = {
  email: 'unknown@r3',
  avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
};

export default {
  // _user: defaultUser,
  // uncomment in production
  _user: null,  
  
  loggedIn() {
    // console.log(store.getters["auth/getToken"])
    // store.dispatch('auth/cookieToState')
    return !!store.getters["auth/getToken"]
    // return !!this._user;
  },

  async logIn(email, password) {
    try {
      // Send request
      
      // var url = "https://r3backendprd.azurewebsites.net/api/Authenticate/login";
      // const response = await fetch(url, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     username: email,
      //     pw: password
      //   }),
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      // if (!response.ok) {
      //   //error, NOT ok
      //   const error = new Error(responseData.message || "Failed to fetch.");
      //   throw error;
      // }
      // const responseData = await response.json();

      //this._user = responseData.token;
      
      //send an auth request and save token in cookie 
      await store.dispatch('auth/login', {username: email, pw: password})
      
      // this._user = { ...defaultUser, email };
      
      this._user = { email };
      
      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    await store.dispatch('auth/logout')
    this._user = null;
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
