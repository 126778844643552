export default {
  async updateManufacturer(context, payload) {

    const newRequest = {
      manufacturerId: payload.manufacturerId,
      manufacturerName: payload.manufacturerName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/manufacturers/${payload.manufacturerId}`;
    // var url = `https://r3backendprd.azurewebsites.net/api/manufacturers/${payload.manufacturerId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updatemanufacturer', payload);
  },

  async addManufacturer(context, payload) {
    const newRequest = {
      manufacturerId: payload.manufacturerId,
      manufacturerName: payload.manufacturerName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/manufacturers`;
    // var url = `https://r3backendprd.azurewebsites.net/api/manufacturers`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.manufacturerId = responseJson.manufacturerId;
    context.commit('addManufacturer', payload);
  },

  async loadManufacturers(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/manufacturers`;
    // var url = `https://r3backendprd.azurewebsites.net/api/manufacturers`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const manufacturer = {
        manufacturerId: responseJson[key].manufacturerId,
        manufacturerName: responseJson[key].manufacturerName,
        };

      responseData.push(manufacturer);
        
    }
    context.commit('setManufacturers', responseData)
  },

  async loadManufacturer(context, payload ) {

    const id = payload.id;

    let manufacturer = {
      manufacturerId: 0,
      manufacturerName: "",
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/manufacturers/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/manufacturers/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      manufacturer = {
        manufacturerId: responseJson.manufacturerId,
        manufacturerName: responseJson.manufacturerName,
        };
    }
    context.commit('setCurrentManufacturer', manufacturer)
  },
};
