export  default {
  setProperties(state, payload) {
    state.properties = payload;
  },
  setPropertyLists(state, payload) {
    state.propertyLists = payload;
  },
  setCurrentPropertyList(state, payload) {
    state.propertyList = payload;
  },
  addPropertyList(state, payload) {
    state.propertyLists.push(payload);
    state.propertyList.propListId = payload.propListId;
  },
  setResponseCode(state, payload) {
    state.responseCode = payload;
  },
  updatePropertyList(state, payload) {
    const pl = state.propertyLists.find(x=>x.propListId===payload.propListId);
    pl.propertyId = payload.propertyId;
    pl.listValue = payload.listValue;
    pl.propertyExternal = payload.propertyExternal;
  },
};